<template>
  <div
    :id="'search-item-result' + result.userId"
    class="search-item-result"
  >
    <v-hover v-slot="{ hover }">
      <v-sheet
        :elevation="hover ? 12 : 2"
        class="pa-3"
        rounded="xl"
        color="neutral"
        @click="viewUserProfile(result.userId)"
      >
        <v-row>
          <v-col
            cols="auto"
            class="mr-auto text-overflow-ellipsis"
          >
            <v-avatar
              class="cursor-pointer"
              size="100"
            >
              <img
                :src="result.picture.url"
                alt="avatar"
              >
            </v-avatar>
          </v-col>
          <v-col class="pl-0 text-overflow-ellipsis">
            <v-row no-gutters>
              <v-col>
                <!--
                <AvailabilityChip
                  v-if="result.availability"
                  class="mb-2"
                  :chip="result.availability"
                />
                -->
                <div
                  class="text-h6"
                  v-text="result.firstName + ' ' + result.lastName"
                />
                <!--
                <template
                  v-if="onlyUniquerJobTitles(result.jobTitles).length > 0"
                >
                  <div
                    class="text-overflow-ellipsis"
                    :title="jobTitlesHeader"
                  >
                    {{ jobTitlesHeader }}
                  </div>
                </template>
                -->
              </v-col>
            </v-row>
            <v-row no-gutters>
              <div
                v-if="result && result.locations && result.locations.length"
                class="text-caption"
              >
                <v-icon
                  small
                  class="icon-link"
                >
                  place
                </v-icon>
                <span
                  v-for="(loc, index) in result.locations"
                  :key="index"
                ><span v-if="index !== 0">, </span>{{ loc }}</span>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-if="result && result.skills && result.skills.length > 0">
            <v-chip
              v-for="(skill, index) in result.skills.slice(0, 5)"
              :key="result.key + skill.skill + index"
              text-color="white"
              class="gradient-chip text-truncate ma-1"
              small
            >
              {{ skill.skill }}
            </v-chip>
            <v-chip
              v-if="(result.skills.length - 5) > 0"
              class="gradient-chip text-truncate ma-1"
              text-color="white"
              small
            >
              + {{ result.skills.length - 5 }} {{ $t('Pages.Search.more') }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row
          v-if="result.ipu && result.ipu.ipuDrivingForces && result.ipu.ipuDrivingForces.length > 0"
          no-gutters
        >
          <v-col>
            <v-chip
              class="elevation-1 gradient-chip text-truncate"
              text-color="white"
              small
            >
              <span
                :class="[result.ipu.ipuWheel[0].personality, 'color-circle']"
              />
              {{ result.ipu.ipuWheel[0].personality }}
            </v-chip>
            <v-chip
              v-for="force in result.ipu.ipuDrivingForces"
              :key="force.id"
              :class="getColor(force.name)"
              class="elevation-1 justify-content-center ma-1"
              text-color="white"
              small
            >
              {{ force.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-sheet>
    </v-hover>
  </div>
</template>

<script>
import { colors } from '@/config/drivingForceColors.js'
import { UPDATE_SCROLL_POSITION } from '@/store/actions.type'
// import AvailabilityChip from '@/common/AvailabilityChip.vue'

export default {
  name: 'SearchResultItem',
  components: {
    // AvailabilityChip
  },
  props: {
    result: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      colors: colors
    }
  },
  computed: {
    jobTitlesHeader: function () {
      return this.result.jobTitles.join(', ')
    }
  },
  methods: {
    viewUserProfile (userId) {
      this.$store.dispatch(UPDATE_SCROLL_POSITION, window.scrollY)
      if (this.$store.state.isAuthenticated) {
        this.$router.push({ name: 'inner-profile', params: { id: userId }, query: {} })
      } else {
        this.$router.push({ name: 'profile', params: { id: userId }, query: {} })
      }
    },
    getColor (name) {
      for (const i in this.colors) {
        if (name === i) {
          return this.colors[i]
        }
      }
    },
    onlyUniquerJobTitles (jobTitleList) {
      return jobTitleList.filter((v, i, a) => a.indexOf(v) === i)
    }
  }
}
</script>

<style scoped>
.search-item-result {
  cursor: pointer;
}
</style>
