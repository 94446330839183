export const availableLocationItems = [
  'Distansarbete',
  'Stockholm',
  'Skåne',
  'Göteborg',
  'Östergötland',
  'Uppsala',
  'Jönköping',
  'Älvsborg',
  'Kronoberg',
  'Örebro',
  'Västerbotten',
  'Gävleborg',
  'Norrbotten',
  'Skaraborg',
  'Dalarna',
  'Västmanland',
  'Västernorrland',
  'Halland',
  'Värmland',
  'Jämtland',
  'Södermanland',
  'Kalmar',
  'Blekinge',
  'Gotland'
]

export default availableLocationItems
