<template>
  <v-container
    :class="[ $store.state.isAuthenticated ? '' : 'neutral--text']"
  >
    <v-row
      class="px-6 pt-4"
      align="center"
    >
      <div class="page-header-left d-flex">
        <v-btn
          id="back-arrow"
          class="ma-1"
          rounded
          text
          outlined
          @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div
          class="text-h4 pl-3"
          v-text="$t('Navigation.search')"
        />
      </div>
      <v-spacer />
      <div class="page-header-right d-flex align-center">
        <v-select
          id="saved-search-select"
          v-model="currentSearch"
          :items="saved"
          item-text="name"
          return-object
          :label="$t('Pages.Search.select_saved_search')"
          :light="!$store.state.isAuthenticated"
          single-line
          @input="loadSavedSearch(currentSearch.search)"
        >
          <template #no-data>
            <v-list dense>
              <v-list-item>
                <v-list-item-content style="color: white !important;">
                  {{ $t('Pages.Search.no_saved_searches') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-select>
        <v-btn
          id="save-search-btn"
          class="ma-1"
          :light="$store.state.isAuthenticated"
          rounded
          :color="$store.state.isAuthenticated ? '' : 'neutral'"
          depressed
          @click="isAuthenticated ? specSaveDialog = true : registerDialog = true"
          v-text="$t('Pages.Search.save_search')"
        />
      </div>
    </v-row>
    <v-container fluid>
      <v-row
        class="mb-4"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="5"
          lg="4"
          class="filter-menu search-terms pb-4"
        >
          <v-card
            flat
            color="neutral"
            class="rounded-xl"
          >
            <v-tabs
              v-model="searchTab"
              background-color="neutral"
              class="rounded-tabs"
              rounded="xl"
              color="warning"
              grow
              centered
            >
              <v-tab>
                {{ $t('Pages.Search.filters') }}
              </v-tab>
              <!--
              <v-tab>
                {{ $t('Pages.Search.saved_searches') }}
              </v-tab>
              -->
              <!-- Awaiting backend support -->
              <!--
              <v-tab>
                {{ $t('Pages.Search.favorites') }}
              </v-tab>
              -->
            </v-tabs>
            <v-tabs-items
              v-model="searchTab"
              class="neutral"
            >
              <v-tab-item>
                <v-expansion-panels
                  v-model="openedPanels"
                  hover
                  accordion
                  multiple
                >
                  <!--                  <v-expansion-panel-->
                  <!--                    id="skill-content"-->
                  <!--                    class="driving-force-header first-header"-->
                  <!--                  >-->
                  <!--                    <v-expansion-panel-header color="neutral">-->
                  <!--                      <div-->
                  <!--                        id="skills-filter"-->
                  <!--                        class="expansion-header"-->
                  <!--                        @click="searchWithConditions('skill')"-->
                  <!--                      >-->
                  <!--                        <strong>{{ $t('Pages.Search.skills') }}</strong>-->
                  <!--                      </div>-->
                  <!--                    </v-expansion-panel-header>-->
                  <!--                    <v-expansion-panel-content color="neutral">-->
                  <!--                      <v-autocomplete-->
                  <!--                        id="input-skill"-->
                  <!--                        ref="searchSkillsRef"-->
                  <!--                        v-model="search.skills"-->
                  <!--                        :items="skillList"-->
                  <!--                        :search-input.sync="searchSkills"-->
                  <!--                        color="accent"-->
                  <!--                        :no-data-text="$t('Pages.Search.skills')"-->
                  <!--                        :label="$t('Pages.Search.skills')"-->
                  <!--                        item-value="skill"-->
                  <!--                        item-text="skill"-->
                  <!--                        class="search-filter-input"-->
                  <!--                        chips-->
                  <!--                        small-chips-->
                  <!--                        clearable-->
                  <!--                        multiple-->
                  <!--                        @change="(event) => updateSearchSkills(event)"-->
                  <!--                      >-->
                  <!--                        <template #no-data>-->
                  <!--                          <v-list v-show="searchSkills !== '' && searchSkills !== null">-->
                  <!--                            <v-list-item-->
                  <!--                              class="v-autocomplete__content"-->
                  <!--                              @change="addSingle($event, 'skills', 'skill')"-->
                  <!--                            >-->
                  <!--                              <v-list-item-content>{{ $t('Pages.Search.no_skills') }}</v-list-item-content>-->
                  <!--                            </v-list-item>-->
                  <!--                          </v-list>-->
                  <!--                        </template>-->
                  <!--                      </v-autocomplete>-->
                  <!--                      &lt;!&ndash; Temporary disabled, need backend support &ndash;&gt;-->
                  <!--                      &lt;!&ndash;  Skill suggestions-->
                  <!--                      <div v-if="skillSuggestions.length && false">-->
                  <!--                        <small id="suggested-skills-label">{{ $t('Pages.Search.suggested_skills') }}</small>-->
                  <!--                        <br>-->
                  <!--                        <v-chip-->
                  <!--                          v-for="(tag, index) in skillSuggestions"-->
                  <!--                          :id="'suggested-skill-' + index"-->
                  <!--                          :key="tag.skill"-->
                  <!--                          class="gradient-chip suggested-skill animate__animated animate__fadeIn"-->
                  <!--                          text-color="white"-->
                  <!--                          @click="addSuggested(tag.skill, 'skills', 'skill')"-->
                  <!--                        >-->
                  <!--                          <span-->
                  <!--                            class="text-truncate"-->
                  <!--                            style="max-width: 259px;"-->
                  <!--                          >-->
                  <!--                            {{ tag.skill }} ({{ tag.subSetCount }})-->
                  <!--                          </span>-->
                  <!--                        </v-chip>-->
                  <!--                      </div>-->
                  <!--                      -&ndash;&gt;-->
                  <!--                    </v-expansion-panel-content>-->
                  <!--                  </v-expansion-panel>-->
                  <v-expansion-panel id="location-content">
                    <v-expansion-panel-header color="neutral">
                      <strong v-text="$t('Pages.Search.location')" />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      color="neutral"
                      eager
                    >
                      <LocationCombobox
                        :selected-locations.sync="selectedLocationItems"
                        @clear-location="clearLocation()"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- <v-expansion-panel id="job-start-content">
                    <v-expansion-panel-header color="neutral">
                      <strong v-text="$t('Pages.Search.assignment_start')" />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="neutral">
                      <v-menu
                        id="menu-available"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        class="search-filter-input"
                        style="width: 100%;"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            id="input-available"
                            v-model="filterAvailableAt"
                            color="accent"
                            :label="$t('Pages.Search.assignment_start_date')"
                            readonly
                            clearable
                            @click:clear="clearAvailability()"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          id="input-date-available"
                          v-model="filterAvailableAt"
                          prepend-icon="event"
                          :first-day-of-week="1"
                          :min="allowedMinDate"
                          @input="saveAvailability"
                          @change="saveDate($event, 'menu1')"
                        />
                      </v-menu>
                    </v-expansion-panel-content>
                  </v-expansion-panel> -->
                  <v-expansion-panel id="education-content">
                    <v-expansion-panel-header color="neutral">
                      <strong>{{ $t('Pages.Search.education') }}</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="neutral">
                      <p
                        style="margin-bottom: 0;"
                        v-html="$t('Pages.Search.select_education')"
                      />
                      <v-select
                        id="select-level-education"
                        v-model="search.educationLevel"
                        :items="educations"
                        color="accent"
                        class="search-filter-input"
                        item-text="text"
                        clearable
                        :label="$t('Pages.Search.degree')"
                        @click:clear="clearEducationLevel()"
                        @input="saveEducationLevel"
                      />
                      <v-text-field
                        v-if="false"
                        id="input-edu"
                        v-model="search.fieldsOfStudy"
                        outlined
                        rounded
                        dense
                        color="accent"
                        :label="$t('Pages.Search.field')"
                        disabled
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    id="language-content"
                    class="driving-force-header"
                  >
                    <v-expansion-panel-header color="neutral">
                      <div
                        class="expansion-header"
                      >
                        <strong>{{ $t('Pages.Search.languages') }}</strong>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="neutral">
                      <v-combobox
                        v-model="search.languages"
                        :items="$i18n.locale === 'sv' ? baseLanguagesSv : baseLanguagesEn"
                        :label="$t('Pages.Search.languages')"
                        color="accent"
                        class="mt-4"
                        item-value="tag.language"
                        item-text="language"
                        multiple
                        dense
                        @change="searchConsultant()"
                      />
                      <div v-if="languageSuggestions.length">
                        <small id="suggested-languages-label">{{ $t('Pages.Search.suggested_languages') }}</small>
                        <br>
                        <v-chip
                          v-for="(tag, index) in languageSuggestions"
                          :id="'suggested-language-' + index"
                          :key="tag.language"
                          text-color="white"
                          class="gradient-chip text-truncate suggested-language"
                          @click="addSuggested(tag.language, 'languages', 'language')"
                        >
                          {{ tag.language }}
                        </v-chip>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    id="personality-content"
                    class="driving-force-header"
                  >
                    <v-expansion-panel-header color="neutral">
                      <div
                        class="expansion-header"
                      >
                        <strong>{{ $t('Pages.Search.behavioral_style') }}</strong>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="neutral">
                      <p class="driving-forces-div">
                        <span>
                          {{ $t('Pages.Search.behavioral_style_text') }}
                        </span>
                        <v-btn
                          class="left-auto position-relative right-position-0"
                          small
                          text
                          fab
                          @click="personalityDialog = true"
                        >
                          <v-icon>help</v-icon>
                        </v-btn>
                      </p>
                      <v-combobox
                        id="select-level-personality"
                        ref="searchPersonalityRef"
                        v-model="search.personality"
                        :items="personalitiesTypes"
                        color="accent"
                        class="search-filter-input"
                        item-text="name"
                        item-value="name"
                        clearable
                        :return-object="false"
                        :label="$t('Pages.Search.personality_type')"
                        @click:clear="clearPersonality()"
                        @change="searchConsultant()"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <div class="d-flex flex-column justify-center align-center py-3">
                    <!--                  <v-btn-->
                    <!--                    id="save-search-btn"-->
                    <!--                    class="ma-1"-->
                    <!--                    light-->
                    <!--                    rounded-->
                    <!--                    depressed-->
                    <!--                    large-->
                    <!--                    @click="isAuthenticated ? specSaveDialog = true : registerDialog = true"-->
                    <!--                    v-text="$t('Pages.Search.save_search')"-->
                    <!--                  />-->
                    <v-btn
                      id="clear-filter"
                      class="ma-1"
                      rounded
                      text
                      @click="onClearAllFilters"
                      v-text="$t('Pages.Search.clear_search')"
                    />
                  </div>
                </v-expansion-panels>
              </v-tab-item>
              <v-tab-item v-if="saved.length > 0">
                <v-list color="neutral">
                  <template v-for="(savedSearch, index) in saved">
                    <v-divider :key="savedSearch.name + '-divider'" />
                    <v-list-item
                      :key="savedSearch.name + '-tile'"
                      @click="loadSavedSearch(savedSearch.search)"
                    >
                      <v-list-item-content
                        class="cursor-pointer"
                      >
                        <v-list-item-title>
                          {{ savedSearch.name }}
                          <em
                            v-if="savedSearch.sendEmail"
                            v-text="$t('Pages.Search.subscribed')"
                          />
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu
                          bottom
                          left
                        >
                          <template #activator="{ on }">
                            <v-btn
                              :id="'more-saved-search-' + index"
                              icon
                              v-on="on"
                            >
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list color="neutral">
                            <v-list-item
                              :id="'edit-saved-search-' + index"
                              @click="editSearchData = Object.assign({}, savedSearch); editSearchDialog = true"
                            >
                              <v-list-item-title v-text="$t('Pages.Search.edit')" />
                            </v-list-item>
                            <v-list-item
                              :id="'delete-saved-search-' + index"
                              @click="deleteSearch(savedSearch.id)"
                            >
                              <v-list-item-title v-text="$t('Pages.Search.delete')" />
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-tab-item>
              <v-tab-item
                v-else
                fill-height
              >
                <v-card
                  color="neutral"
                  class="rounded-xl"
                  align="center"
                  justify="center"
                  flat
                >
                  <v-icon
                    class="mt-4"
                    large
                    color="grey lighten-1"
                  >
                    search
                  </v-icon>
                  <v-card-text v-text="$t('Pages.Search.no_saved_searches')" />
                </v-card>
              </v-tab-item>
              <!-- Favorites tab / Awaiting more backend support -->
              <!--
              <v-tab-item>
                <v-list v-if="favorites.length > 0">
                  <template v-for="(favorite, index) in favorites">
                    <v-divider :key="favorite.id" />
                    <v-list-item
                      :key="favorite.id + '-' + favorite.name"
                      @click="openFavorites(favorite.members, favorite.id)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ favorite.name }}
                          <span v-if="favorite.members">
                            ({{ favorite.members.length }})
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu
                          bottom
                          left
                        >
                          <template #activator="{ on }">
                            <v-btn
                              :id="'more-favorite-' + index"
                              icon
                              v-on="on"
                            >
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              :id="'edit-favorite-search-' + index"
                              @click="openEditListDialog(favorite)"
                            >
                              <v-list-item-title v-text="$t('Pages.Search.edit')" />
                            </v-list-item>
                            <v-list-item
                              :id="'delete-favorite-search-' + index"
                              @click="deleteListDialog = true; listToDelete = favorite.id"
                            >
                              <v-list-item-title v-text="$t('Pages.Search.delete')" />
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                <v-card
                  v-else
                  color="neutral"
                  class="rounded-xl"
                  align="center"
                  height="100%"
                  flat
                >
                  <v-icon
                    large
                    color="grey lighten-1"
                  >
                    star
                  </v-icon>
                  <v-card-text v-text="$t('Pages.Search.no_favorites')" />
                </v-card>
              </v-tab-item>
              -->
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="7"
          lg="8"
        >
          <v-row justify="center">
            <v-col
              cols="12"
            >
              <SearchInputBox
                :search-term="search.searchTerm"
                @clear-search="onClearAllFilters()"
                @submit-keywords="onSubmitKeywords($event)"
              />
            </v-col>
            <v-col
              v-show="searchLoader && (!checkSearch() || isFavorite)"
              cols="12"
              class="animate__animated animate__fadeIn"
            >
              <v-container class="fill-height">
                <v-row
                  align="center"
                >
                  <v-col class="text-center">
                    <v-progress-circular
                      :size="100"
                      :width="5"
                      color="accent"
                      indeterminate
                    />
                    <div
                      class="text-heading-5 mt-2"
                    >
                      {{ $t(visibleQuote) }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              v-show="searchResult.length && !searchLoader"
              id="search-results"
              cols="12"
            >
              <SearchResultItem
                v-for="(result, index) in searchResult"
                :key="index"
                :index="index"
                :result="result"
                class="animate__animated animate__fadeIn mb-6"
              />
            </v-col>
            <v-col
              v-show="searchResult.length === 0 && search.searchTerm !== '' && !searchLoader"
              id="search-no-results"
              cols="12"
              class="text-center animate__animated animate__fadeIn"
            >
              <div class="no-data-container">
                <h2>{{ $t('Pages.Search.no_matching') }}</h2>
                <p>{{ $t('Pages.Search.still') }}</p>
                <v-btn
                  color="secondary"
                  class="neutral--text"
                  rounded
                  depressed
                  @click="contactDialog = true"
                >
                  {{ $t('Pages.Search.let_us') }}
                </v-btn>
              </div>
            </v-col>
            <v-col
              v-show="searchResult.length === 0 && search.searchTerm === '' && !searchLoader"
              id="search-empty"
              cols="12"
              class="text-center animate__animated animate__fadeIn"
            >
              <div class="no-data-container">
                <div>{{ $t('Pages.Search.info_1') }}</div>
                <div>{{ $t('Pages.Search.info_2') }}</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="mobileSnackbar"
      color="neutral"
      :bottom="true"
      rounded="pill"
      :timeout="-1"
    >
      <span class="text--secondary">
        {{ $t('Pages.Search.found') }} {{
          searchResult.length
        }} {{ searchResult.length === 1 ? $t('Pages.Search.consultant') : $t('Pages.Search.consultants') }}
      </span>
      <template #action="{ attrs }">
        <v-btn
          color="accent"
          text
          rounded
          v-bind="attrs"
          @click="$vuetify.goTo('#search-results')"
          v-text="$t('Pages.Search.view_results')"
        />
      </template>
    </v-snackbar>
    <v-dialog v-model="registerDialog">
      <v-card
        color="neutral"
        class="rounded-xl"
      >
        <v-card-title>
          {{ $t('Pages.Search.thank_you_but') }}
        </v-card-title>
        <v-card-text>
          <h3 class="mt-4">
            {{ $t('Pages.Search.premium_comes') }}
          </h3>
          <p class="mt-4">
            {{ $t('Pages.Search.for_more_1') }}
            <router-link :to="{ name: 'accounts-login' }">
              {{ $t('Pages.Search.for_more_2') }}
            </router-link>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            rounded
            @click="registerDialog = false"
            v-text="$t('Pages.Search.close')"
          />
          <v-btn
            href="mailto:info@gigport.se"
            light
            depressed
            rounded
            v-text="$t('Pages.Search.contact_us')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="personalityDialog">
      <v-card
        color="neutral"
        class="rounded-xl"
      >
        <v-card-title class="text-h6 font-weight-regular justify-space-between">
          <span>Behavioral style</span>
        </v-card-title>
        <v-card-text>
          <p>
            Behavioral research suggests that the most effective people are those who understand themselves, both their
            strengths and weaknesses, so they can develop strategies to meet the demands of their environment. A
            person's behavior is a necessary and integral part of who they are. Efficient teams consist of many
            different personalities.
          </p>
          <v-expansion-panels
            hover
            accordion
            multiple
          >
            <v-expansion-panel
              v-for="personalityType in personalitiesTypes"
              :key="personalityType.name"
            >
              <v-expansion-panel-header color="neutral">
                <strong>{{ personalityType.name }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="neutral">
                {{ personalityType.description }}
                <div class="pieContainer search-pie">
                  <div
                    is="pieContainer"
                    :pie-size="'medium'"
                    :personality="personalityType.name"
                  >
                    <div
                      slot="background"
                      class="pieBackground search-pie-background"
                    />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="button-container">
            <v-btn
              text
              rounded
              @click.stop="personalityDialog = false"
              v-text="$t('Pages.Search.close')"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="specSaveDialog">
      <CustomDialog
        title-icon="save"
        :title="$t('Pages.Search.save_search')"
      >
        <div slot="custom">
          <v-text-field
            id="search-name-field"
            slot="custom"
            ref="saveSearch"
            v-model="specName"
            :counter="30"
            :maxlength="30"
            :rules="[importedRules.required]"
            color="accent"
            :label="$t('Pages.Search.name_save_search')"
            required
          />
          <!--          <v-checkbox-->
          <!--            v-model="sendEmail"-->
          <!--            :label="$t('Pages.Search.save_search_checkbox')"-->
          <!--          />-->
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-name-btn"
            text
            rounded
            @click="specSaveDialog = false; $refs.saveSearch.reset()"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-name-btn"
            :disabled="!specName"
            class="cta-btn"
            depressed
            rounded
            @click="saveNewSearch"
            v-text="$t('Common.save')"
          />
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog v-model="editListDialog">
      <CustomDialog
        title-icon="edit"
        :title="$t('Pages.Search.rename_list')"
      >
        <div slot="custom">
          <v-text-field
            id="search-list-field"
            slot="custom"
            ref="saveList"
            v-model="currentList.name"
            :counter="30"
            :maxlength="30"
            :rules="[importedRules.required, importedRules.only_spaces]"
            color="accent"
            :label="$t('Pages.Search.new_list_name')"
            required
          />
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-list-btn"
            text
            rounded
            @click="editListDialog = false; $refs.saveList.reset()"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-list-btn"
            :disabled="!currentList.name"
            class="cta-btn"
            rounded
            @click="editListName"
            v-text="$t('Common.save')"
          />
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog v-model="editSearchDialog">
      <CustomDialog
        title-icon="save"
        :title="$t('Pages.Search.edit_saved_search')"
      >
        <div slot="custom">
          <v-text-field
            id="search-name-field"
            slot="custom"
            ref="editSearchRef"
            v-model="editSearchData.name"
            :counter="30"
            :maxlength="30"
            :rules="[importedRules.required]"
            color="accent"
            :label="$t('Pages.Search.name_save_search')"
            required
          />
          <!-- Awaiting backend support -->
          <!--          <v-checkbox-->
          <!--            v-model="editSearchData.sendEmail"-->
          <!--            :label="$t('Pages.Search.save_search_checkbox')"-->
          <!--          />-->
        </div>
        <div
          slot="buttons"
          class="button-container"
        >
          <v-btn
            id="close-name-btn-2"
            text
            rounded
            @click="editSearchDialog = false"
            v-text="$t('Common.cancel')"
          />
          <v-btn
            id="save-name-btn-2"
            :disabled="!editSearchData.name"
            class="cta-btn"
            rounded
            depressed
            @click="editSavedSearch"
            v-text="$t('Common.save')"
          />
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog v-model="deleteListDialog">
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Search.delete_list')"
        :second-title="$t('Pages.Search.delete_list_text')"
        :action-button="{
          class: 'deleteAction',
          id: 'list-delete-btn',
          text: $t('Pages.Search.delete')
        }"
        :close-button="{
          id: 'list-delete-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteListDialog = false"
        @perform-action="deleteList(listToDelete)"
      />
    </v-dialog>
    <v-dialog v-model="contactDialog">
      <v-card
        color="neutral"
        class="rounded-xl"
      >
        <v-card-title
          class="text-h6 font-weight-regular justify-space-between"
          v-text="$t('Pages.Search.contact_title')"
        />
        <v-card-text>
          <v-form
            id="contact-form"
            ref="contactFormRef"
            v-model="validContactForm"
            @submit.prevent="onNeedHelpFormSubmit()"
          >
            <v-text-field
              id="company-name"
              v-model="contactForm.details"
              type="text"
              color="accent"
              :label="$t('Pages.Search.contact_desc')"
              :rules="[v => !!v || $t('Validation.required')]"
              required
            />
            <v-text-field
              id="contact-name"
              v-model="contactForm.name"
              type="text"
              color="accent"
              :label="$t('Pages.Profile.contact_person')"
              :rules="[v => !!v || $t('Validation.required')]"
              required
            />
            <v-text-field
              id="contact-email"
              v-model="contactForm.email"
              type="text"
              color="accent"
              :label="$t('Pages.Profile.email')"
              :rules="emailRules"
              required
            />
            <v-text-field
              id="contact-phone"
              v-model="contactForm.phone"
              type="tel"
              color="accent"
              :label="$t('Pages.Profile.phone')"
            />
            <v-checkbox
              id="contact-checkbox"
              v-model="contactForm.terms"
              color="accent"
              :rules="[v => !!v || $t('Pages.Profile.terms_validation')]"
            >
              <template #label>
                <div @click.stop>
                  {{ $t('Pages.Profile.terms_1') }}
                  <router-link
                    id="terms-link"
                    :to="{ name: 'tos-users' }"
                    target="_blank"
                    @click.stop
                    v-text="$t('Footer.terms')"
                  />
                  {{ $t('Pages.Profile.terms_2') }}
                  <router-link
                    :to="{ name: 'privacy-policy' }"
                    target="_blank"
                    @click.stop
                  >
                    <span v-text="$t('Footer.privacy')" />
                  </router-link>
                </div>
              </template>
            </v-checkbox>
            <div class="button-container">
              <v-btn
                text
                rounded
                @click="contactDialog = false"
                v-text="$t('Pages.Search.close')"
              />
              <v-btn
                id="submit-contact-form"
                type="submit"
                color="secondary"
                class="neutral--text ml-2"
                rounded
                :disabled="!validContactForm || loadingContactForm"
                :loading="loadingContactForm"
              >
                {{ $t('Pages.Profile.submit') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomDialog from '@/common/CustomDialog.vue'
import LocationCombobox from '@/common/LocationCombobox.vue'
import SearchResultItem from '@/pages/search/components/SearchResultItem.vue'
import SearchInputBox from '@/pages/search/components/SearchInputBox.vue'
import pieContainer from '@/pages/profile/components/DiscPie.vue'

import { formatDate } from '@/config/formatDate.js'
import { rules } from '@/config/validation-rules.js'
import { importedDrivingForces } from '@/config/drivingForces.js'
import { importedPersonality } from '@/config/personality.js'
import { personalities } from '@/config/personalityShort.js'
import { colors } from '@/config/drivingForceColors.js'
import { userHandler } from '@/config/userHandler.js'
import { mapState } from 'vuex'

import { CLEAR_SEARCH_FILTERS, UPDATE_SCROLL_POSITION, UPDATE_SEARCH_FILTERS } from '@/store/actions.type'

export default {
  name: 'SearchComponent',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  components: {
    SearchInputBox,
    SearchResultItem,
    LocationCombobox,
    CustomDialog,
    pieContainer
    // EditFavoriteGroupDialog
  },
  props: {
    createAlert: {
      type: Function,
      default: () => {
      }
    }
  },
  data () {
    return {
      openedPanels: [0, 1, 2, 3], // [0, 1, 2, 3],
      expanded: [],
      quotes: [
        'Pages.Search.quote1',
        'Pages.Search.quote2',
        'Pages.Search.quote3'
      ],
      visibleQuote: '',
      listToDelete: '',
      deleteListDialog: false,
      currentList: '',
      editListDialog: false,
      offsetTop: 0,
      userHandler: userHandler,
      formatDate,
      languagesModel: ['English', 'Swedish'],
      importedRules: rules,
      specSaveDialog: false,
      registerDialog: false,
      editSearchDialog: false,
      editSearchData: [],
      personalityDialog: false,
      mobileSnackbar: false,
      // filterAvailableAt: formatDate.basic(new Date()),
      searchResult: [],
      companies: [],
      saved: [],
      itemsPerPage: 20,
      headers: [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: this.$t('Pages.Search.name'),
          align: 'start',
          value: 'firstName'
        },
        {
          text: this.$t('Pages.Search.assignment_start'),
          value: 'availability'
        }
      ],
      allowedMinDate: formatDate.basic(new Date()),
      skill: '',
      searchTab: null,
      drivingForces: importedDrivingForces,
      personalitiesTypes: importedPersonality,
      personalities: personalities,
      colors: colors,
      search: {
        searchTerm: '',
        locations: [],
        educationLevel: '',
        languages: [],
        personality: ''
      },
      searchLoader: false,
      language: '',
      locations: [],
      selectedLocationItems: [],
      fieldOfStudy: '',
      educations: [
        { text: this.$t('Pages.Profile.doctorate_or_higher'), value: 'doctorate_or_higher' },
        { text: this.$t('Pages.Profile.licentiate'), value: 'licentiate' },
        { text: this.$t('Pages.Profile.masters'), value: 'masters' },
        { text: this.$t('Pages.Profile.bachelors'), value: 'bachelors' },
        { text: this.$t('Pages.Profile.secondary'), value: 'secondary' },
        { text: this.$t('Pages.Profile.primary'), value: 'primary' },
        { text: this.$t('Pages.Profile.higher_vocational'), value: 'higher_vocational' },
        { text: this.$t('Pages.Profile.course'), value: 'course' },
        { text: this.$t('Pages.Profile.certification'), value: 'certification' }
      ],
      baseLanguagesSv: ['Engelska', 'Svenska', 'Norska', 'Danska', 'Finska', 'Spanska', 'Tyska', 'Franska'],
      baseLanguagesEn: ['English', 'Swedish', 'Norwegian', 'Danish', 'Finnish', 'Spanish', 'German', 'French'],
      menu1: false,
      personality: '',
      searchResultsLeft: [],
      skillSuggestions: [],
      languageSuggestions: [],
      personalitySuggestions: [],
      skillList: [],
      langList: [],
      eduList: [],
      infiniteLoader: false,
      tableView: true,
      specName: '',
      currentSearch: '',
      searchArray: [],
      autocomplete: null,
      total: 0,
      sendEmail: false,
      visibleCardResults: [],
      favorites: [],
      isFavorite: false,
      timeoutId: null,
      getPremiumDialog: false,
      currentListId: null,
      contactDialog: false,
      loadingContactForm: false,
      validContactForm: true,
      emailRules: [
        v => !!v || this.$tc('Validation.required'),
        v => /.+@.+/.test(v) || this.$tc('Validation.email')
      ],
      contactForm: {
        id: '',
        name: '',
        email: '',
        details: '',
        phone: '',
        terms: false
      }
    }
  },
  computed: {
    ...mapState([
      'brandName',
      'isAuthenticated',
      'user',
      'searchScrollPosition',
      'searchFilters'
    ])
  },
  watch: {
  // This watcher is for when searchResult changes
    searchResult: function (newSearchResult) {
      this.mobileSnackbar = newSearchResult.length > 0
    },

    // This watcher is for when selectedLocationItems changes
    selectedLocationItems: function (locationList) {
      this.onSelectLocationChange(locationList)
    },

    // This watcher is for changes in the route query parameters, specifically 'keywords'
    '$route.query.keywords': {
      handler: function (keywords) {
      // If you need to log the keywords, uncomment the following line:
      // console.log('watch: keywords', keywords);
        if (keywords) {
          this.onSubmitKeywords(keywords)
        }
      },
      deep: true,
      immediate: true
    }
  },

  beforeMount () {
    if (this.emptySearch()) {
      this.addTags()
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.scrollTo(0, this.searchScrollPosition)
    })
    if (this.isAuthenticated && this.user.verified) {
      this.getSavedSearches()
      // this.getFavorites()
    }
    this.search = this.$store.state.searchFilters
    this.selectedLocationItems = this.search.locations
    this.infiniteScroll()
    this.updateQuote()
    // this.getTags('personalities', 'personalitySuggestions')
    setInterval(this.updateQuote, 4500)
  },
  methods: {
    updateQuote: function () {
      this.visibleQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)]
    },
    onSubmitKeywords (keywords) {
      // console.log('keywords', keywords)
      this.search.searchTerm = keywords
      // console.log('this.search.searchTerm', this.search.searchTerm)
      this.skillSuggestions.push(keywords)
      // console.log('onSubmitKeywords', this.search.searchTerm)
      this.searchConsultant(keywords)
    },
    emptySearch () {
      for (const i in this.$store.state.search) {
        if (this.$store.state.search[i] === null) {
          // Do something?
        } else {
          return true
        }
      }
      return false
    },
    onScroll () {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
      if (this.searchResult.length > 0) {
        this.mobileSnackbar = this.offsetTop <= 1100
      }
    },
    loadMoreResults () {
      if (this.visibleCardResults.length < this.searchResult.length) {
        this.infiniteLoader = true
        setTimeout(() => {
          Array.prototype.push.apply(
            this.visibleCardResults,
            this.searchResultsLeft.splice(0, this.searchResultsLeft.length + 1)
          )
          this.infiniteLoader = false
        }, 1000)
      }
    },
    infiniteScroll () {
      window.onscroll = () => {
        const scrollPercentage =
          (document.documentElement.scrollTop + window.innerHeight) /
          document.documentElement.offsetHeight
        if (scrollPercentage > 0.9 && this.searchResultsLeft.length > 0) {
          this.infiniteLoader = true
          if (this.timeoutId) {
            clearTimeout(this.timeoutId)
          }
          const self = this
          self.timeoutId = setTimeout(function () {
            getMoreResults(self)
          }, 1200)
        }
      }

      function getMoreResults (self) {
        if (self.searchResultsLeft.length <= 6) {
          setTimeout(() => {
            Array.prototype.push.apply(
              self.visibleCardResults,
              self.searchResultsLeft.splice(
                0,
                self.searchResultsLeft.length + 1
              )
            )
            self.infiniteLoader = false
          }, 1000)
        } else {
          setTimeout(() => {
            Array.prototype.push.apply(
              self.visibleCardResults,
              self.searchResultsLeft.splice(0, 6)
            )
            self.infiniteLoader = false
          }, 1000)
        }
      }
    },
    updateSearchSkills (event) {
      this.search.skills = event
      this.searchConsultant()
    },
    getColor (name) {
      for (const i in this.colors) {
        if (name === i) {
          return this.colors[i]
        }
      }
    },
    openFavorites (members, id) {
      this.currentListId = id
      this.searchLoader = true
      this.searchResult = []
      this.visibleCardResults = []
      this.searchResult = members.filter(function (n) {
        if (n !== null) {
          n.favoritesGroups = [{}] // to make star 'on' also in favorites list
          n.key = n.id // since favorite lists don't have key as unique identifier but all key values in search are dependent on them
          return n
        }
      })
      this.searchResultsLeft = this.searchResult.slice()
      Array.prototype.push.apply(
        this.visibleCardResults,
        this.searchResultsLeft.splice(0, 6)
      )
      this.tableView = false
      setTimeout(() => {
        this.searchLoader = false
      }, 1000)
      this.isFavorite = members.length > 0
    },
    searchConsultant (keywords) {
      if (keywords) {
        this.search.searchTerm = keywords
        // console.log('keywords', keywords)
      }
      this.isFavorite = false
      this.searchLoader = true
      this.visibleCardResults = []

      // console.log('search', search)
      const listOfSearchFilters = Object.values(this.search)
      this.$gtm.trackEvent({
        category: 'Search',
        action: 'add filter',
        label: listOfSearchFilters,
        value: 0
      })
      this.$gtm.trackEvent({
        category: 'Search',
        action: 'searchTerm',
        label: this.search.searchTerm,
        value: 0
      })

      this.$http.post('/search/v3', this.search).then(
        res => {
          // console.log('this.search', this.search)
          this.$store.dispatch(UPDATE_SEARCH_FILTERS, this.search)
          const response = res.data.results
          this.searchResult = response.filter(function (n) {
            return n !== null
          })
          this.searchResultsLeft = this.searchResult.slice()
          Array.prototype.push.apply(
            this.visibleCardResults,
            this.searchResultsLeft.splice(0, 6)
          )
          for (const val in this.$store.state.search) {
            this.$store.state.search[val] = null
          }
          this.$nextTick(() => {
            window.scrollTo(0, this.searchScrollPosition)
          })
          this.searchLoader = false
        },
        err => {
          console.error(err)
          this.searchLoader = false
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    addSingle (e, array, itemVar) {
      if (e === null || e === undefined) {
        return
      }
      this.search[array].push(e)
      this[itemVar] = ''
      if (itemVar !== 'fieldOfStudy') {
        this.searchWithConditions(itemVar)
      } else {
        this.searchConsultant()
      }
      if (itemVar === 'skill') {
        this.$refs.searchSkillsRef.reset()
        this.$refs.searchSkillsRef.blur()
        this.skill = null
      } else if (itemVar === 'personality') {
        this.$refs.searchPersonalityRef.reset()
        this.$refs.searchPersonalityRef.blur()
        this.personality = null
      } else if (itemVar === 'language') {
        this.$refs.searchLanguageRef.reset()
        this.$refs.searchLanguageRef.blur()
        this.language = null
      } else if (itemVar === 'fieldOfStudy') {
        this.$refs.searchFieldOfStudysRef.reset()
        this.$refs.searchFieldOfStudysRef.blur()
        this.fieldOfStudy = null
      }
    },
    remove (item, array, name, index) {
      this.search[array].splice(this.search[array].indexOf(item), 1)
      this.search[array] = [...this.search[array]]
      if (this.checkSearch()) {
        this.checkEmptySearch(name)
      } else {
        this.searchWithConditions(name)
      }
    },
    checkEmptySearch (name) {
      let isEmpty = true
      for (const i in this.search) {
        if (i === 'availableAt' || i === 'educationLevel') {
          if (this.search[i] !== null && this.search[i] !== '') {
            isEmpty = false
          }
        } else {
          if (this.search[i].length > 0) {
            isEmpty = false
          }
        }
      }
      if (isEmpty === true) {
        if (name === 'language') {
          // this.getTags('languages', 'languageSuggestions')
        }
        // if (name === 'personality') {
        //   this.getTags('personalities', 'personalitySuggestions')
        // }
      } else {
        this.searchConsultant()
      }
    },
    handleColor (user) {
      // get users primary color for personality
      if (user === '') {
        return 'divide'
      }
      if (!user.personality) return
      const personality = user.personality
      const ipu = {
        redBackground: 'Conductor',
        redYellowBackground: 'Persuader',
        yellowBackground: 'Promoter',
        yellowGreenBackground: 'Relater',
        greenBackground: 'Supporter',
        greenBlueBackground: 'Coordinator',
        blueBackground: 'Analyser',
        blueRedBackground: 'Implementor'
      }
      for (const x in ipu) {
        if (ipu[x].includes(personality)) {
          return x
        }
      }
    },
    addSuggested (tag, type, name) {
      if (name === 'personality') {
        this.search[type] = [tag]
      } else {
        this.search[type].push(tag)
      }
      this.searchConsultant()
    },
    searchWithConditions (name) {
      if (!this.checkSearch()) {
        this.searchConsultant()
      }
      let newName
      if (name !== 'personality' && name !== 'fieldOfStudy') {
        newName = name + 's'
      } else if (name === 'fieldOfStudy') {
        newName = 'fieldsOfStudy'
      } else if (name === 'personality') {
        newName = 'personalities'
      }

      const array = name + 'Suggestions'
      const list = name + 'List'
      const search = {
        tag: newName,
        conditions: this.search
      }
      this.$http.post('/search/tags', search).then(
        res => {
          this[array] = res.data.results.slice(0, 10)
          this[list] = res.data.results
        },
        err => {
          console.error(err)
        }
      )
    },
    checkSearch () {
      let search
      if (!this.emptySearch()) {
        search = this.search
      } else {
        search = this.$store.state.search
      }
      let isEmpty = true
      for (const i in search) {
        if (i === 'availableAt' || i === 'educationLevel') {
          if (search[i] !== null && search[i] !== '') {
            isEmpty = false
          }
        } else {
          if (search[i] && search[i].length > 0) {
            isEmpty = false
          }
        }
      }
      return isEmpty
    },
    saveEducationLevel () {
      this.searchConsultant()
    },
    clearAvailability () {
      this.searchConsultant()
    },
    clearPersonality () {
      this.search.personalities = []
      this.searchConsultant()
    },
    clearLocation () {
      this.search.locations = []
      this.searchConsultant()
    },
    clearEducationLevel () {
      this.search.educationLevel = null
      this.searchConsultant()
    },
    saveAvailability () {
      // this.search.availableAt = this.filterAvailableAt
      this.searchConsultant()
    },
    onClearAllFilters () {
      this.searchResult = []
      this.$store.dispatch(UPDATE_SCROLL_POSITION, 0)
      this.$store.dispatch(CLEAR_SEARCH_FILTERS)
      this.search = this.$store.state.searchFilters
      this.selectedLocationItems = this.search.locations
      if (this.$route.query) {
        this.$router.push({ query: { ...this.$route.query, keywords: undefined } })
      }
    },
    editSavedSearch () {
      const body = {
        name: this.editSearchData.name,
        search: this.editSearchData.search,
        sendEmail: false
        // sendEmail: this.editSearchData.sendEmail
      }
      this.$http.post('/search/save/' + this.editSearchData.id, body)
        .then(
          res => {
            this.saved = res.data.searches
            this.editSearchDialog = false
            this.createAlert('Your search was saved', 'check_circle', 'success')
            this.$refs.editSearchRef.reset()
          },
          err => {
            this.$root.$emit('errorMessage', err.response)
          }
        )
    },
    saveNewSearch () {
      const body = {
        name: this.specName,
        search: this.search,
        sendEmail: false
        // sendEmail: this.sendEmail
      }
      this.$http.post('/search/save', body).then(
        res => {
          this.saved = res.data.searches
          this.specSaveDialog = false
          this.createAlert('Your search was saved', 'check_circle', 'success')
          this.$refs.saveSearch.reset()
        },
        err => {
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    getSavedSearches () {
      this.$http.get('/search/save').then(
        res => {
          this.saved = []
          for (let i = 0; i < res.data.searches.length; i++) {
            this.saved.push(res.data.searches[i])
          }
        },
        err => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response.errorMessage)
        }
      )
    },
    loadSavedSearch (search) {
      // if (this.$route.name === 'search') return
      this.search = search
      this.searchConsultant()
    },
    getPersonalityText (name) {
      for (const i in this.personalities) {
        if (name === i) {
          return this.personalities[i]
        }
      }
    },
    deleteSearch (searchId) {
      // if (this.$route.name === 'search') return
      this.$http.delete('/search/save/' + searchId).then(
        res => {
          this.saved = res.data.searches
          this.createAlert(
            'Your search was successfully deleted',
            'check_circle',
            'success'
          )
        },
        err => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    saveDate (e, menu) {
      this[menu] = false
    },
    onSelectLocationChange (locationList) {
      this.search.locations = locationList
      this.searchConsultant()
    },
    getFavorites () {
      if (this.$route.name === 'search') return
      this.$http.get('favorites').then(
        res => {
          this.favorites = res.data.groups
          if (this.searchTab === 2) {
            for (const i in this.favorites) {
              if (this.favorites[i].id === this.currentListId) {
                this.openFavorites(this.favorites[i].members, this.currentListId)
              }
            }
          }
        },
        err => {
          console.error(err)
        }
      )
    },
    editListName () {
      const listName = this.currentList.name
      this.$http.put('favorites/' + this.currentList.id, { name: listName })
        .then(
          res => {
            this.favorites = res.data.groups
            this.editListDialog = false
          },
          err => {
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
          }
        )
    },
    deleteList (id) {
      this.$http.delete('favorites/' + id).then(
        res => {
          this.favorites = res.data.groups
          this.deleteListDialog = false
          this.createAlert(
            'List successfully deleted!',
            'check_circle',
            'success'
          )
        },
        err => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    openEditListDialog (favorite) {
      this.editListDialog = true
      this.currentList = { ...favorite }
    },
    onNeedHelpFormSubmit () {
      this.loadingContactForm = true
      if (this.$refs.contactFormRef.validate()) {
        this.$http.post('search/inquiry', this.contactForm).then(
          () => {
            setTimeout(() => {
              this.loadingContactForm = false
              this.contactDialog = false
              this.$refs.contactFormRef.reset()
              this.$root.$emit('successMessage', this.$t('Pages.Search.let_us_success'))
            }, 1000)
          }, err => {
            console.error(err)
            setTimeout(() => {
              this.loadingContactForm = false
              this.$root.$emit('errorMessage', err.response)
            }, 1000)
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
.v-expansion-panel::before {
  box-shadow: none;
}

.rounded-tabs .v-slide-group__wrapper {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
</style>
