<template>
  <v-combobox
    v-model="selectedLocationItems"
    :items="availableLocationItems"
    :label="$t('Common.select_location')"
    color="accent"
    class="mt-4"
    clearable
    multiple
    @click:clear="clearLocation()"
    @change="addLocation()"
  />
</template>

<script>
import { availableLocationItems } from '@/data/availableLocationItems.js'
export default {
  name: 'LocationCombobox',
  props: {
    selectedLocations: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedLocationItems: [],
    availableLocationItems
  }),
  watch: {
    selectedLocations: {
      handler (newValue) {
        this.selectedLocationItems = [...newValue]
      },
      immediate: true
    }
  },
  methods: {
    async addLocation () {
      this.$emit('update:selectedLocations', this.selectedLocationItems)
    },
    clearLocation () {
      this.$emit('update:selectedLocations', [])
      this.$emit('clear-location')
    }
  }
}
</script>
