<template>
  <SearchComponent />
</template>
<script>
import SearchComponent from '@/pages/search/components/SearchComponent.vue'
export default {
  name: 'Search',
  components: {
    SearchComponent
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'profile') {
      this.$store.state.search = this.$children[0].search
    }
    next()
  },
  props: {
    createAlert: {
      type: Function,
      default: function () { }
    }
  },
  mounted () {
    if (this.$store.state.isAuthenticated) {
      if (this.$route.name === 'search') {
        this.$router.push({ name: 'inner-search' })
      }
    }
    this.$gtm.trackEvent({
      category: 'Growth',
      action: 'page_sok',
      label: 'Page Sök',
      value: 0,
      noninteraction: true
    })
  }
}
</script>
