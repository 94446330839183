<template>
  <v-form @submit.prevent="onSubmitKeywords(keywords)">
    <v-text-field
      v-model="keywords"
      :placeholder="$t('Pages.Search.search_title')"
      filled
      rounded
      outlined
      prepend-inner-icon="mdi-magnify"
      :dense="isDense"
      hide-details
      :class="isDense ? 'mw-250' : ''"
      :light="!$store.state.isAuthenticated && !isDense"
      :color="isDense || $store.state.isAuthenticated ? 'warning' : 'neutral'"
      clearable
      @click:clear="onClearClick()"
      @click="isInputFocused = true"
    />
  </v-form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchInputBox',
  props: {
    searchTerm: {
      type: String,
      default: () => ''
    },
    isDense: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      keywords: this.$route.query.keywords,
      isInputFocused: false
    }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  watch: {
    searchTerm: {
      immediate: true,
      handler (searchTerm) {
        // console.log('searchTerm is: ', searchTerm)
        if (searchTerm !== null && searchTerm !== undefined) {
          this.keywords = searchTerm
        }
      }
    },
    $route: {
      immediate: true,
      handler (to, _) {
        if (to.query.keywords && !this.isDense) {
          // console.log('to.query.keywords', to.query.keywords)
          this.keywords = to.query.keywords
          this.$emit('submit-keywords', to.query.keywords)
        }
      }
    }
  },
  methods: {
    onClearClick () {
      this.$emit('clear-search')
      this.onSubmitKeywords('')
    },
    onSubmitKeywords (keywords) {
      // console.log('SearchInputBox onSubmitKeywords: ', keywords)
      if (!this.isAuthenticated) {
        // If current route is not inner-search, then redirect to inner-search
        if (this.$route.name !== 'search') {
          this.$router.push({ name: 'search', query: { keywords: keywords } })
        }
        this.$emit('submit-keywords', keywords)
      } else {
        if (this.$route.name !== 'inner-search') {
          this.$router.push({ name: 'inner-search', query: { keywords: keywords } })
        }
        this.$emit('submit-keywords', keywords)
      }
      if (this.isDense) {
        this.keywords = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mw-250 {
  max-width: 250px;
}
</style>
